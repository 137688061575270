/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* src/index.css */
/* src/index.css */
@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-Thin.ttf') format('truetype');
  font-weight: 100; 
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-Medium.ttf') format('truetype');
  font-weight: 500; 
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-SemiBold.ttf') format('truetype');
  font-weight: 600; 
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-ExtraLight.ttf') format('truetype');
  font-weight: 200; 
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

body {
  font-family: 'Barlow', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
